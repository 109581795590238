<template>
    <section class="section__body">

        <!--- Navigation --->
        <MaintenanceList_navigation />

        <!--- Container --->
        <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
            MaintenanceList
        </section>
    </section>
</template>

<script>
    import MaintenanceList_navigation from "./MaintenanceList_navigation.vue";
    import { mapGetters } from "vuex";

    export default {
        name: "MaintenanceList",
        components: {
            MaintenanceList_navigation,
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsCount",
            ])
        },
    }
</script>

<style scoped>

</style>